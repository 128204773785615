import React, { useState } from "react";
import PurchaseOrderSummary from "./PurchaseOrderSummary";

const PaymentInstructions = ({
  eventData,
  selectedPaymentMethod,
  prevStep,
  ticketSelections,
  ticketOptions,
}) => {
  var totalPrice = 0;
  var accountNumber = '94809280293803';
  var [transactionNumber, setTransactionNumber] = useState('');
  // var [file, setFile] = useState(undefined);
  var file;
  const ticketTypes = () => {
    var ticketTypes = "";
    ticketSelections.map(
      (selection) =>
        (ticketTypes += `${selection.qty} x ${
          ticketOptions.find((option) => option.id === selection.id).type
        }, `)
    );
    ticketTypes = ticketTypes.replace(/,\s*$/, "");
    return ticketTypes;
  };

  const ticketPrices = () => {
    var ticketPrices = "";
    ticketSelections.map((selection) => {
      const price = ticketOptions.find(
        (option) => option.id === selection.id
      ).price;
      totalPrice += price * selection.qty;
      return (ticketPrices += `${selection.qty} x Rs. ${price}, `);
    });
    ticketPrices = ticketPrices.replace(/,\s*$/, "");
    return ticketPrices;
  };

  const handleCancel = () => {
    window.location.href = '/';
  };
  return (
    <>
      <div className="purchase-payment-instructions-and-order-summary">
        <div className="purchase-payment-instruction-container">
          <h2 className="heading">Your Invoice Number:</h2>
          <h2 className="invoice-number">1232431254152344</h2>
          <div className="payment-instructions-container">
            <h2 className="payment-instructions-heading">
              How to pay with {selectedPaymentMethod}:
            </h2>
            <p className="payment-instructions-text">1. Open your Digit wallet application</p>
            <p className="payment-instructions-text">2. Select Bank Transfer option</p>
            <p className="payment-instructions-text">3. Select "{selectedPaymentMethod}" as bank payment method.</p>
            <p className="payment-instructions-text">4. Enter our account number {accountNumber}.</p>
            <p className="payment-instructions-text">5. Confirm our details and pay with ease.</p>
            <p className="payment-instructions-text">6. Upload your payment receipt for confirmation.</p>
          </div>
          <div className="input-container">
            <h6 className="input-label">Enter your Transaction Number: </h6>
            <input
              type="text"
              name="transcation-number"
              value={transactionNumber}
              onChange={(e) => setTransactionNumber(e.target.value)}
            />
          </div> 
          <div className="receipt-upload-container">
            <h2 className="heading">Upload your receipt</h2>
            <label htmlFor="receipt-upload-input" className="receipt-upload-label">
              <img src={process.env.PUBLIC_URL + "/icons/upload.svg"} alt="Upload Icon" />
              {/* <span>Upload Receipt</span> */}
            </label>
            <input
              id="receipt-upload-input"
              className="receipt-upload-input"
              type="file"
              accept="application/pdf"
              style={{ display: 'none' }}
              onChange={(e) => {
                // setFile(e.target.files[0]);
                file = e.target.files[0];
                if (file) {
                  const container = document.querySelector('.receipt-upload-container');
                  container.innerHTML = '';
                  const tickMark = document.createElement('span');
                  tickMark.innerHTML = '&#10003;';
                  tickMark.style.color = 'green';
                  tickMark.style.fontSize = '24px';
                  container.appendChild(tickMark);

                  const fileName = document.createElement('span');
                  fileName.textContent = ` ${file.name}`;
                  fileName.className = 'file-name';
                  fileName.style.marginLeft = '10px';
                  container.appendChild(fileName); 
                }
              }}
            />
          </div>
          <div className="payment-instructions-actions-button-container">
            <button 
              className="payment-instructions-confirm-button" 
              disabled={ transactionNumber === ''}
              onClick={() => { console.log(file); window.location.href = '/purchase-confirmation'}}
            >
              Confirm your Payment
            </button>
            <button className="payment-instructions-cancel-button" onClick={handleCancel}>Cancel</button>
          </div>
        </div>
        <PurchaseOrderSummary
          eventData={eventData}
          ticketTypes={ticketTypes()}
          ticketPrices={ticketPrices()}
          totalPrice={totalPrice}
        />
      </div>
    </>
  );
};

export default PaymentInstructions;
