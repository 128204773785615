import React from "react";
const LoadingBox = () => {
  return (
    <div className="loading-box-container">
      <div className="loading-box">
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default LoadingBox;
