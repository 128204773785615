import React from 'react';

const Tooltip = ({ text, position }) => {
  return (
    <div
      className="tooltip"
      style={{ top: position.y, left: position.x }}
    >
      {text}
    </div>
  );
};

export default Tooltip;