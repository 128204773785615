import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#fff",
    color: "white",
    display: 'flex',
    alignItems: 'center',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    backgroundColor: '#303458',
    borderRadius: '15px',
    padding: '30px 20px',
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
  },
  invoiceNumber: {
    fontSize: 20,
    marginBottom: 10,
  },
  eventTitle: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 5,
    color: '#cac8c6',
  },
  eventDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
  ticketContainer: {
    marginTop: 20,
    borderTop: "1px solid white",
    paddingTop: 10,
  },
  ticket: {
    fontSize: 12,
    marginBottom: 5,
  },
});

const PdfTicket = ({ invoice, eventTitle, location, date, tickets }) => (
  <Document>
    <Page style={styles.page}>
      {/* Header */}
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.invoiceNumber}>Invoice# {invoice}</Text>
          <Text style={styles.eventTitle}>{eventTitle}</Text>
          <Text style={styles.eventDetails}>
            {location} | {date}
          </Text>
        </View>

        {/* Tickets */}
        <View style={styles.ticketContainer}>
          {tickets.map((ticket, index) => (
            <Text key={index} style={styles.ticket}>
              Ticket {index + 1}: {ticket.name} - {ticket.price}
            </Text>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default PdfTicket;

// const App = () => {
//   const invoice = '123456';
//   const eventTitle = 'React Conference 2024';
//   const location = 'Istanbul, Turkey';
//   const date = 'December 10, 2024';
//   const tickets = [
//     { name: 'General Admission', price: '$50' },
//     { name: 'VIP Access', price: '$120' },
//   ];

//   const handleOpenPDF = async () => {
//     // Create the PDF blob
//     const doc = <MyDocument invoice={invoice} eventTitle={eventTitle} location={location} date={date} tickets={tickets} />;
//     const asBlob = await pdf(doc).toBlob();

//     // Open the PDF in a new tab
//     const pdfURL = URL.createObjectURL(asBlob);
//     window.open(pdfURL, '_blank');
//   };

//   return (
//     <div>
//       <button onClick={handleOpenPDF}>Open PDF</button>
//     </div>
//   );
// };

// export default App;
