const PaymentSelectionOption = ({
  method,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => {
  return (
    <div
      style={
        selectedPaymentMethod === method
          ? {
              border: '1px solid var(--px-purple)',
              WebkitBoxShadow: '7px 7px 0px 0px var(--px-purple)',
              MozBoxShadow: '7px 7px 0px 0px var(--px-purple)',
              BoxShadow: '7px 7px 0px 0px var(--px-purple)',
            }
          : {}
      }
      className={"payment-selection-option"}
      onClick={() => setSelectedPaymentMethod(method)}
    >
      <div className="payment-selection-option-radio">
        <input
          type="radio"
          name="paymentMethod"
          value={method}
          checked={selectedPaymentMethod === method}
          onChange={() => setSelectedPaymentMethod(method)}
        />
        <label>{method}</label>
      </div>
      <div className="payment-selection-option-icon">
        <img src={"/icons/credit-card.svg"} alt="Credit Card Icon" />
      </div>
    </div>
  );
};

export default PaymentSelectionOption;
