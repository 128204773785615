import React, { useState } from "react";
import InfoBanner from "./InfoBanner";
import PaymentNextStepButton from "./PaymentNextStepButton";
import PurchaseOrderSummary from "./PurchaseOrderSummary";
import PaymentSelectionOption from "./PaymentSelectionOption";

const ContactInformation = ({
  eventData,
  ticketSelections,
  ticketOptions,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  prevStep,
  nextStep,
}) => {
  var totalPrice = 0;
  const ticketTypes = () => {
    var ticketTypes = "";
    ticketSelections.map(
      (selection) =>
        (ticketTypes += `${selection.qty} x ${
          ticketOptions.find((option) => option.id === selection.id).type
        }, `)
    );
    ticketTypes = ticketTypes.replace(/,\s*$/, "");
    return ticketTypes;
  };

  const ticketPrices = () => {
    var ticketPrices = "";
    ticketSelections.map((selection) => {
      const price = ticketOptions.find(
        (option) => option.id === selection.id
      ).price;
      totalPrice += price * selection.qty;
      return (ticketPrices += `${selection.qty} x Rs. ${price}, `);
    });
    ticketPrices = ticketPrices.replace(/,\s*$/, "");
    return ticketPrices;
  };

  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const paymentMethods = [
    "Credit/Debit Card",
    "EasyPaisa Account",
    "SadaPay Account",
    "JazzCash Account",
    "NayaPay Account",
  ];

  return (
    <>
      <div className="purchase-payment-method-and-order-summary">
        <div className="purchase-payment-method-container">
            <h2>Select Payment Method</h2>
            {paymentMethods.map((method) => (
              <div key={method} className="payment-selection-option-container">
                {/* <input
                  type="radio"
                  name="paymentMethod"
                  value={method}
                  checked={selectedPaymentMethod === method}
                  onChange={() => setSelectedPaymentMethod(method)}
                />
                <label>{method}</label> */}
                <PaymentSelectionOption method={method} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} />
              </div>
            ))}

          <div
            className="payment-next-step"
            style={{ width: "100%", alignItems: "flex-end" }}
          >
            <PaymentNextStepButton
              content={"Continue to Payment"}
              onClick={nextStep}
              enabled={selectedPaymentMethod === "" ? false : true}
            />
          </div>
        </div>
        <PurchaseOrderSummary
          eventData={eventData}
          ticketTypes={ticketTypes()}
          ticketPrices={ticketPrices()}
          totalPrice={totalPrice}
        />
      </div>
    </>
  );
};

export default ContactInformation;
