import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TicketSelection from "../components/TicketSelection";
import ContactInformation from "../components/ContactInformation";
import PaymentMethod from "../components/PaymentMethod";
import PaymentInstructions from "../components/PaymentInstructions";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // React Router hook
import axios from "axios";

// Helper function to format event name for URL
const formatEventNameForUrl = (name) => {
  return name?.toLowerCase().replace(/\s+/g, "-") || ""; // Safeguard for undefined names
};

const BookingPage = () => {
  const { eventName } = useParams();
  const [step, setStep] = useState(1);
  const [ticketSelections, setTicketSelections] = useState([]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    emailConfirmation: "",
    phone: "",
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const location = useLocation();
  const [eventData, setEventData] = useState(location.state?.event || null);
  const navigate = useNavigate(); // Hook for navigation

  // console.log("Event data here ", eventData);

  // Function to fetch ticket options based on event ID
  useEffect(() => {
    const fetchUserDetailsAndTickets = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          // Verify user token
          const userResponse = await axios.post(`${apiUrl}/verify-token`, { token });
          if (userResponse.status === 200) {
            setUserDetails({
              firstName: userResponse.data.userDetails.first_name,
              lastName: userResponse.data.userDetails.last_name,
              email: userResponse.data.userDetails.email,
              emailConfirmation: userResponse.data.userDetails.email,
            });

            // Fetch ticket options only if user is authenticated
            if (eventData?.id) {
              try {
                const ticketResponse = await axios.get(`${apiUrl}/tickets`, {
                  params: { event_id: eventData.id },
                });
                setTicketOptions(
                  ticketResponse.data.map((ticket) => ({
                    id: ticket.ticket_title,
                    type: ticket.ticket_title,
                    price: ticket.total_ticket_price,
                    description: ticket.ticket_description,
                  }))
                );
              } catch (error) {
                console.error("Error fetching ticket options:", error);
              }
            }
          } else {
            console.log("Failed to verify token");
            navigate("/login"); // Redirect to sign-in page
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          navigate("/login"); // Redirect to sign-in page
        }
      } else {
        navigate("/login"); // Redirect to sign-in page
      }
    };

    fetchUserDetailsAndTickets();
  }, [apiUrl, eventData]);

  // Fetch event details if eventData is missing or incomplete
  useEffect(() => {
    const fetchEventDetails = async () => {
      if (!eventData?.id) {
        try {
          const U_eventName = formatEventNameForUrl(eventName);
          const response = await axios.get(
            `${apiUrl}/event-details/${U_eventName}`
          );
          const eventData = response.data;
          console.log(eventData);
          let parsedLinks = {};
          try {
            parsedLinks = JSON.parse(eventData.links);
          } catch (error) {
            console.error("Failed to parse links:", error);
          }

          const checkImageExists = (url) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = url;
              img.onload = () => resolve(true);
              img.onerror = () => resolve(false);
            });
          };

          const isValidImage = await checkImageExists(eventData.adv_banner);
          const backgroundImage = isValidImage
            ? eventData.adv_banner
            : "../../Adv_imgs/moqah_bg.png";

          setEventData({ ...eventData, parsedLinks, backgroundImage });
        } catch (error) {
          console.error("Error fetching event details:", error);
        }
      }
    };

    fetchEventDetails();
  }, [eventData, apiUrl, eventName]);

  // Fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(`${apiUrl}/verify-token`, {
            token,
          });
          if (response.status === 200) {
            setUserDetails({
              firstName: response.data.userDetails.first_name,
              lastName: response.data.userDetails.last_name,
              email: response.data.userDetails.email,
              emailConfirmation: response.data.userDetails.email,
              phone: undefined,
            });
          } else {
            console.log("Failed to verify token");
            setUserDetails(undefined);
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          setUserDetails(undefined);
        }
      } else {
        setUserDetails(undefined);
      }
    };

    fetchUserDetails();
  }, []);
  // Handle back button navigation
  const handleBackButtonNavigation = () => {
    if (step === 1) {
      window.location.href = `/event-details/${formatEventNameForUrl(
        eventName
      )}`;
    } else {
      setStep(step - 1);
    }
  };

  // Navigation functions for steps
  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  // Render the appropriate step component
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <TicketSelection
            eventData={eventData}
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            setTicketSelections={setTicketSelections}
            nextStep={nextStep}
            prevStep={() => handleBackButtonNavigation()}
          />
        );
      case 2:
        return (
          <ContactInformation
            eventData={eventData}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <PaymentMethod
            eventData={eventData}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 4:
        return (
          <PaymentInstructions
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            eventData={eventData}
            selectedPaymentMethod={selectedPaymentMethod}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header searchBar={false} />
      <div className="payment-flow-container">
        <div className="payment-navigator">
          <button onClick={handleBackButtonNavigation} className="back-button">
            <img src={`${iconsPath}/arrow-blue.svg`} alt="back button" />
          </button>
          <h1 className="payment-step-heading">{`Step ${step}: ${
            [
              "Ticket Options",
              "Buyer Contact Information",
              "Payment Method",
              "Payment Instructions",
            ][step - 1]
          }`}</h1>
        </div>
        <div className="payment-step-container">{renderStep()}</div>
      </div>
      <Footer />
    </>
  );
};

export default BookingPage;
