export const loadGoogleAnalytics = () => {
  return new Promise((resolve, reject) => {
    // Check if gtag is already loaded
    if (window.gtag) {
      resolve();
      return;
    }

    // Create the gtag.js script tag
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`;
    script.async = true;

    // Initialize GA when the script loads
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', process.env.REACT_APP_GA_TRACKING_ID, {
        // Optimize sampling rate or add other GA config options here
        sample_rate: 50, // 50% sampling for reduced data volume
      });
      // console.log('Google Analytics loaded successfully.');
      resolve();
    };

    script.onerror = () => {
      console.error('Failed to load Google Analytics script.');
      reject(new Error('Failed to load Google Analytics'));
    };

    // Append the script to the document head
    document.head.appendChild(script);
  });
};

// Log a page view
export const logPageView = () => {
  if (window.gtag) {
    const path = window.location.pathname + window.location.search;
    window.gtag('event', 'page_view', {
      page_path: path,
    });
    // console.log(`Logged page view: ${path}`);
  } else {
    console.warn('Google Analytics is not initialized. Page view not logged.');
  }
};
