import React from "react";
import { useNavigate } from "react-router-dom";

const SecondaryButton = ({ content, onClick }) => {
  const navigate = useNavigate();

  return (
    <div className="secondary-button inline-flex">
      <button onClick={onClick}>
        <h6 style={{ color: content === 'Logout' ? 'red' : 'white' }}>
          {content}
        </h6>
      </button>
    </div>
  );
};

export default SecondaryButton;
