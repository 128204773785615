import React, { useState, startTransition } from "react";
import { useNavigate } from "react-router-dom";

const plans = [
  {
    title: "Basic",
    description: "Great for basic listing needs",
    price: "FREE",
    details: [
      "SINGLE USAGE",
      "Event Cover Image: single standard",
      "Consolidated event Information",
      "Customized URL",
      "Standard Placement: detail page only",
    ],
  },
  {
    title: "Conversion Focus",
    description: "Great for focused event needs",
    price: "RS. 1000",
    frequency: "PER WEEK",
    details: [
      "Event Cover Image: 2-3 custom Images",
      "Consolidated event Information",
      "Customized URL",
      "Standard Placement: Featured on Home",
      "Customized Call to Action Button",
      "Social Media post",
      "On Page SEO: Standard",
      "Video Listing",
    ],
    popular: true,
  },
  {
    title: "Conversion Max",
    description: "Ideal for advanced and maximum event needs",
    price: "RS. 2000",
    frequency: "PER WEEK",
    details: [
      "Event Cover Image: 3 custom Images",
      "Consolidated event Information",
      "Customized URL",
      "Standard Placement: Featured on Home & Discover",
      "Customized Call to Action Button",
      "Social Media post",
      "On Page SEO: Advanced",
      "Video Listing",
      "User visit and click statistics",
      "Emailed Analytics results",
    ],
  },
  {
    title: "Custom Quotes",
    description: "Need a custom plan? Your solution is just a message away!",
    details: [
      "Event Cover Image: single standard",
      "Consolidated event Information",
      "Customized URL",
      "Standard Placement: detail page only",
    ],
  },
];

const PricingSection = () => {
  const [activeCard, setActiveCard] = useState(null);
  const navigate = useNavigate(); 

  const handleButtonClick = (planTitle) => {
    startTransition(() => {
      if (planTitle === "Custom Quotes") {
        navigate("/account?tab=contact");
      } else {
        navigate("/add-event");
      }
      setTimeout(() => {
        window.scrollTo(0, 0); // Scroll to the top after a short delay
      }, 500); // Adjust delay as needed
    });
  };
  
  return (
    <div id="pricing" className="bg-gray-50 py-16">
      <h2 className="text-center text-4xl font-extrabold text-blue-600 mb-12">
        Pricing
      </h2>
      <div className="flex justify-center mb-12">
        <div className="flex bg-gray-200 rounded-full p-1 shadow-md">
          <button className="px-6 py-2 bg-blue-500 text-white rounded-full mx-1 font-semibold">
            Weekly
          </button>
          <button className="px-6 py-2 text-blue-500 hover:bg-gray-300 hover:text-gray-700 rounded-full mx-1 relative font-semibold">
            Monthly
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 rounded-full text-white font-bold text-xs opacity-0 hover:opacity-100 transition duration-300">
              Coming Soon
            </div>
          </button>
          <button className="px-6 py-2 text-blue-500 hover:bg-gray-300 hover:text-gray-700 rounded-full mx-1 relative font-semibold">
            Annually
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 rounded-full text-white font-bold text-xs opacity-0 hover:opacity-100 transition duration-300">
              Coming Soon
            </div>
          </button>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 px-6 md:px-12 lg:px-24">
        {plans.map((plan, index) => (
          <div
            key={index}
            onClick={() => setActiveCard(index)}
            className={`bg-white rounded-lg shadow-lg border p-6 relative transform hover:scale-105 transition duration-300 ${
              activeCard === index ? "border-blue-500" : "border-gray-300"
            }`}
          >
            {plan.popular && (
              <p className="absolute top-4 right-4 bg-yellow-300 text-yellow-800 text-sm px-3 py-1 rounded-full">
                Most Popular!
              </p>
            )}
            <h3 className="text-xl font-bold text-blue-600 text-center">
              {plan.title}
            </h3>
            <p className="text-center text-gray-600 mb-4">{plan.description}</p>
            {plan.price && (
              <div className="text-center text-4xl font-bold text-gray-800 mb-2">
                {plan.price}
              </div>
            )}
            {plan.frequency && (
              <p className="text-center text-sm text-gray-500 mb-6">
                {plan.frequency}
              </p>
            )}
            <button
              onClick={() => handleButtonClick(plan.title)} // Button click handler to navigate
              className={`py-2 px-6 rounded-lg w-full transition ${
                plan.title === "Custom Quotes"
                  ? "bg-blue-400 hover:bg-blue-500 text-white"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              }`}
            >
              {plan.title === "Custom Quotes" ? "CONTACT US" : "GET LISTED"}
            </button>
            <ul className="mt-6 space-y-3 text-gray-800">
              {plan.details.map((detail, i) => (
                <li key={i} className="flex items-center">
                  <span className="text-blue-500 mr-2">✔</span> {detail}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingSection;
