import React, { useEffect, useState } from "react";
import axios from "axios"; 
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import InformationSection from "../components/InformationSection";
import PricingSection from "../components/PricingSection";
import TrendingCities from "../components/TrendingCities";

const Packages = () => {
  const [trendingCities, setTrendingCities] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const cityData = [
    {
      name: "Islamabad",
      headline: "The beautiful capital of Pakistan",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/islamabad.png?updatedAt=1732244413779",
    },
    {
      name: "Rawalpindi",
      headline: "The Historic Heart of Pakistan",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/rawalpindi.png?updatedAt=1732244416670",
    },
    { name: "Lahore", headline: "The city of gardens", imageUrl: "https://ik.imagekit.io/9vwcvqozx/lahore.png?updatedAt=1732244413746" },
    {
      name: "Karachi",
      headline: "The city of lights",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/karachi.png?updatedAt=1732244413754",
    },
    {
      name: "Muzaffarabad",
      headline: "Scenic Beauty",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/muzaffarabad.png?updatedAt=1732244413755",
    },
  ];
  
  
  useEffect(() => {
    axios
      .get(`${apiUrl}/cities`) // Fetch cities from the cities API
      .then((response) => {
        const cities = response.data.cities.map((city) => {
          const cityInfo = cityData.find((c) => c.name === city);
          return {
            heading: city,
            subheading: cityInfo ? cityInfo.headline : "",
            imageUrl: cityInfo ? cityInfo.imageUrl : "",
            destination: `/discover`, // Adjust the destination if needed
          };
        });
        setTrendingCities(cities); // Set the state with the city data
      })
      .catch((error) => {
        console.error("There was an error fetching the trending cities!", error);
      });
  }, []);

  
  return (
    <div className="bg-[#04092C] min-h-screen text-white flex flex-col m-0 p-0">
      <Header searchBar={true} />
      <HeroSection /> 
      <TrendingCities data={trendingCities} />
      <InformationSection />
      <PricingSection />
      <Footer />
    </div>
  );
};

export default Packages;
