import React from 'react';

const PaymentNextStepButton = ({ dest, content, onClick, enabled }) => {
  return (
    <div
      className={enabled ? "payment-next-step-button" : "payment-next-step-button payment-next-step-button-disabled"}
      onClick={enabled ? onClick : null}
      onMouseEnter={enabled ? (e) => {
        e.currentTarget.querySelector('h6').style.transform = 'scale(1.1)';
      } : null}
      onMouseLeave={enabled ? (e) => {
        e.currentTarget.querySelector('h6').style.transform = 'scale(1)';
      } : null}
    >
      {dest ? (
        <a
          href={dest}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <h6
            style={{
              transition: 'transform 0.2s ease-in-out',
            }}
          >
            {content}
          </h6>
        </a>
      ) : (
        <h6
          style={{
            transition: 'transform 0.2s ease-in-out',
          }}
        >
          {content}
        </h6>
      )}
    </div>
  );
};

export default PaymentNextStepButton;
