import React from "react";

const HeroSection = () => {
  const handleViewPricing = () => {
    const pricingSection = document.querySelector("#pricing");
    pricingSection.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="relative flex flex-col items-center text-center min-h-[40vh] px-4 overflow-hidden">

      {/* Decorative Shapes */}
      <div className="absolute top-10 left-10 w-20 h-20 bg-blue-500 rounded-full animate-bounce hidden md:block"></div>
      <div className="absolute top-10 right-20 w-32 h-16 bg-blue-400 rounded-lg animate-bounce hidden md:block"></div>
      <div className="absolute top-40 right-1/4 w-16 h-16 bg-blue-600 rounded-full animate-pulse hidden md:block"></div>
      <div className="absolute top-60 right-20 w-20 h-10 bg-blue-300 skew-y-6 hidden md:block"></div>
      <div className="absolute top-20 right-1/3 w-24 h-24 bg-blue-200 rounded-full opacity-50 blur-xl hidden md:block"></div>
      <div className="absolute bottom-1/3 left-10 w-24 h-16 bg-blue-500 clip-triangle hidden md:block"></div>

      <h1 className="text-4xl font-bold leading-tight md:text-5xl md:leading-snug mb-6 text-white">
        We design beautiful <br /> Event Discovery<br /> Pages, faster and <br /> Accessible
      </h1>
      <p className="text-lg font-light leading-relaxed md:text-2xl md:leading-relaxed text-white mb-6">
        A premium Event listing service that's accessible to all, <br />
        without the premium price tag with just a click of a button <br />
        across all major cities of Pakistan.
      </p>
      <button
        onClick={handleViewPricing}
        className="px-8 py-3 bg-gradient-to-r from-blue-400 to-blue-600 text-white font-semibold rounded-full shadow-lg transition-transform transform hover:scale-110 hover:shadow-xl">
        View Pricing
      </button>
    </div>
  );
};

export default HeroSection;
