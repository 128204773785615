const PurchaseOrderSummary = ({eventData, ticketTypes, ticketPrices, totalPrice}) => {
    const iconsPath = process.env.PUBLIC_URL + "/icons/";
    return (
        <div className="purchase-order-summary-container">
          <h2>Event Details</h2>
          {eventData ? (
            <div className="purchase-event-details">
              <div
                className="event-image"
                style={{ backgroundImage: `url(${eventData.backgroundImage})` }}
              ></div>
              <div className="basic-information">
                <h1 className="heading">{eventData.name}</h1>
                {/* <h2 className="subheading">{data.location}</h2> */}
                <div className="location-section">
                  <img src={iconsPath + "map-pin-black.svg"} />
                  <p className="location">{eventData.venue}</p>
                </div>
                <div className="location-section">
                  <img src={iconsPath + "calendar-black.svg"} />
                  <p className="location">{`${eventData.date}, ${eventData.time}`}</p>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <h2 style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>Order Summary</h2>
          <div className="summary-container">
            <div className="summary-row">
              <h6>Ticket Type</h6>
              <h6 className="ticket-types">{ticketTypes}</h6>
            </div>
          </div>
          <div className="summary-container">
            <div className="summary-row">
              <h6>Ticket Price</h6>
              <h6>{ticketPrices}</h6>
            </div>
            <div className="summary-row">
              <h6>Service and Handling</h6>
              <h6>-</h6>
            </div>
            <div className="summary-row">
              <h6>Admin Fee</h6>
              <h6>-</h6>
            </div>
          </div>
          <div className="summary-row">
            <h6>Total</h6>
            <h6>Rs. {totalPrice}</h6>
          </div>
        </div>
    );
};

export default PurchaseOrderSummary;
