import React from 'react';

const PurpleButton = ({ dest, content, onClick }) => {
  return (
    <div
      className="purple-button inline-flex"
      style={{
        backgroundColor: '#0088cc',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick} // Attach the onClick handler
      onMouseEnter={(e) => {
        e.currentTarget.querySelector('h6').style.transform = 'scale(1.1)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.querySelector('h6').style.transform = 'scale(1)';
      }}
    >
      {dest ? (
        <a
          href={dest}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <h6
            style={{
              transition: 'transform 0.2s ease-in-out',
            }}
          >
            {content}
          </h6>
        </a>
      ) : (
        <h6
          style={{
            transition: 'transform 0.2s ease-in-out',
          }}
        >
          {content}
        </h6>
      )}
    </div>
  );
};

export default PurpleButton;
