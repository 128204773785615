const InfoBanner = ({type, content}) => {
    return (
        <>
            <div className={type === "error" ? 'error-info-banner' : type === "warning" ? 'warning-info-banner' : 'info-banner'}>
                {content}
            </div>
        </>
    );
};

export default InfoBanner;