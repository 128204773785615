import React, { useState } from "react";
import LoadingBox from "./LoadingBox";
import TicketOptionCard from "./TicketOptionCard";
import PaymentNextStepButton from "./PaymentNextStepButton";

const TicketSelection = ({
  eventData,
  ticketSelections,
  ticketOptions,
  setTicketSelections,
  nextStep,
  prevStep,
}) => {
  const [nextStepEnabled, setNextStepEnabled] = useState(false);
  

  const handleSelect = (id, qty) => {
    setTicketSelections((prevSelections) => {
      const updatedSelections = prevSelections.filter(
        (ticket) => ticket.id !== id
      );
      if (qty > 0) {
        updatedSelections.push({ id, qty });
      }
      // Check if the button should be enabled based on updated selections
      setNextStepEnabled(updatedSelections.length > 0);
      return updatedSelections;
    });
  };

  const checkIfSelected = () => {
    console.log(ticketSelections.length);
    setNextStepEnabled(ticketSelections.length > 0);
  };

  const iconsPath = process.env.PUBLIC_URL + "/icons/";

  return (
    <>
      {/* <h2>Select Tickets</h2>
      {ticketOptions.map((option) => (
        <div key={option.id}>
          <p>
            {option.type} - ${option.price}
          </p>
          <input
            type="number"
            min="0"
            placeholder="Quantity"
            value={
              ticketSelections.find((ticket) => ticket.id === option.id)?.qty ||
              ""
            }
            onChange={(e) =>
              handleSelect(option.id, parseInt(e.target.value) || 0)
            }
          />
        </div>
      ))} */}
      {eventData ? (
        <>
          <div className="purchase-event-information-container">
            <div
              className="purchase-event-image"
              style={{ backgroundImage: `url(${eventData.backgroundImage})` }}
            ></div>
            <div className="purchase-event-details">
              <div className="basic-information">
                <h1 className="heading">{eventData.name}</h1>
                {/* <h2 className="subheading">{data.location}</h2> */}
                <div className="location-section">
                  <img src={iconsPath + "map-pin-black.svg"} />
                  <p className="location">{eventData.venue}</p>
                </div>
                <div className="location-section">
                  <img src={iconsPath + "calendar-black.svg"} />
                  <p className="location">{`${eventData.date}, ${eventData.time}`}</p>
                </div>
                <p className="description">{eventData.short_summary}</p>
              </div>
            </div>
          </div>
          <hr className="horizontal-line" />
          <div className="ticket-options-container">
            {ticketOptions.map((option) => (
              <TicketOptionCard
                details={{
                  id: option.id,
                  type: option.type,
                  price: option.price,
                  description: option.description,
                  quantity:
                    ticketSelections.find((ticket) => ticket.id === option.id)
                      ?.qty || 0,
                }}
                updateQuantity={(id, qty) => {
                  handleSelect(id, qty);
                }}
              />
            ))}
          </div>
          <div className="payment-next-step">
            <PaymentNextStepButton
              content={"Continue"}
              onClick={nextStep}
              enabled={nextStepEnabled}
            />
          </div>
        </>
      ) : (
        <LoadingBox />
      )}
    </>
  );
};

export default TicketSelection;
