const TicketOptionCard = ({ updateQuantity, details }) => {
    const iconsPath = process.env.PUBLIC_URL + "/icons/";
  return (
    <div className="ticket-option-card">
      <div className="ticket-section-top">
        <h2 className="ticket-type">{details.type}</h2>
        <h6 className="ticket-description">{details.description}</h6>
      </div>
      <div className="ticket-section-bottom">
        <h2 className="ticket-price">Rs. {details.price}</h2>
        <div className="ticket-quantity-controls">
          <button
            className="ticket-quantity-control-button"
            onClick={() => updateQuantity(details.id, details.quantity - 1)}
          >
           <img src={iconsPath + "/remove-purple.svg"} /> 
          </button>
          <h2 className={details.quantity === 0 ? "ticket-quantity ticket-quantity-zero" : "ticket-quantity"}>{details.quantity}</h2>
          <button
            className="ticket-quantity-control-button"
            onClick={() => updateQuantity(details.id, details.quantity + 1)}
          >
            <img src={iconsPath + "/add-purple.svg"} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketOptionCard;
