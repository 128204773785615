export const filterReducer = (filterState, {type, payload}) => {
    switch (type){
        case "LTH":
            return {
                ...filterState,
                sort: payload
            }
        case "HTL":
            return {
                ...filterState,
                sort: payload
            }
        
        case "TYPE":
            // console.log(filterState)
            return {
                ...filterState,
                type: payload.check ? [...filterState.type, payload.option] : (filterState.type.length > 0 ? filterState.type.filter(item => item !== payload.option) : [])
            }
        case "CATEGORY":
            return {
                ...filterState,
                cat: payload.check ? [...filterState.cat, payload.option] : (filterState.cat.length > 0 ? filterState.cat.filter(item => item !== payload.option) : [])
            }
        case "LOCATION":
            return {
                ...filterState,
                loc: payload.check ? [...filterState.loc, payload.option] : (filterState.loc.length > 0 ? filterState.loc.filter(item => item !== payload.option) : [])
            }
        case "PRICE":
            // console.log(payload.option)
            return {
                ...filterState,
                price: payload.option
            }
        case "CLEAR":
            return {
                ...filterState,
                sort: "",
                type: [],
                loc: [],
                cat: [],
                // price: [0, 100000],
            }

        default:
            return filterState

    }
}

export const inputFilterReducer = (inputFilterState, {payload}) => {
    return {
        ...inputFilterState,
        searchTerm: payload.inputSearchTerm,
        location: payload.zipCode,
        date: payload.selectedDate
    }
}