import React, { useState } from "react";
import InfoBanner from "./InfoBanner";
import PaymentNextStepButton from "./PaymentNextStepButton";
import PurchaseOrderSummary from "./PurchaseOrderSummary";

const ContactInformation = ({
  eventData,
  userDetails,
  setUserDetails,
  ticketSelections,
  ticketOptions,
  prevStep,
  nextStep,
}) => {
  var totalPrice = 0;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const ticketTypes = () => {
    var ticketTypes = "";
    ticketSelections.map(
      (selection) => (ticketTypes += `${selection.qty} x ${ticketOptions.find(option => option.id === selection.id).type}, `)
    );
    ticketTypes = ticketTypes.replace(/,\s*$/, "");
    return ticketTypes;
  };

  const ticketPrices = () => {
    var ticketPrices = "";
    ticketSelections.map(
      (selection) => {
        const price = ticketOptions.find(option => option.id === selection.id).price;
        totalPrice += price * selection.qty;
        return (ticketPrices += `${selection.qty} x Rs. ${price}, `)
      }
    );
    ticketPrices = ticketPrices.replace(/,\s*$/, "");
    return ticketPrices;
  };

  const iconsPath = process.env.PUBLIC_URL + "/icons/";

  return (
    <>
      <div className="purchase-contact-and-order-summary">
        <div className="purchase-contact-information-container">
          <InfoBanner
            className="purchase-info-banner"
            type="info"
            content="E-tickets will be sent to your email address too, please make sure your email address is correct."
          />
          <div className="row">
            <div className="input-container">
              <h6 className="input-label">First Name</h6>
              <input
                type="text"
                name="name"
                value={userDetails.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="input-container">
              <h6 className="input-label">Last Name</h6>
              <input
                type="text"
                name="name"
                value={userDetails.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="input-container">
              <h6 className="input-label">Email</h6>
              <input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleChange}
              />
            </div>
            <div className="input-container">
              <h6 className="input-label">Confirm Email</h6>
              <input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="input-container">
            <h6 className="input-label">Phone</h6>
            <input
              type="tel"
              name="phone"
              value={userDetails.phone}
              onChange={handleChange}
            />
          </div>
          <div className="payment-next-step" style={{width: "100%",alignItems: "flex-end"}}>
            <PaymentNextStepButton
              content={"Continue to Payment"}
              onClick={nextStep}
              enabled={true}
            />
          </div>
          {/* <h3>Order Summary:</h3>
      <ul>
        {ticketSelections.map((ticket) => (
          <li key={ticket.id}>
            Ticket {ticket.id}: Quantity {ticket.qty}
          </li>
        ))}
      </ul>
      <button onClick={prevStep}>Previous</button>
      <button onClick={nextStep}>Next</button> */}
        </div>
        <PurchaseOrderSummary eventData={eventData} ticketTypes={ticketTypes()} ticketPrices={ticketPrices()} totalPrice={totalPrice}/>
      </div>
    </>
  );
};

export default ContactInformation;
