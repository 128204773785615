import React, { useState, startTransition } from "react";
import { Navigate } from "react-router-dom"; // Import Navigate
import Logo from "../logo.svg";

const InformationSection = () => {
  const [redirect, setRedirect] = useState(false);

  const handleButtonClick = () => {
    setRedirect(true); // Trigger the redirect when the button is clicked
  };

  if (redirect) {
    return <Navigate to="/account?tab=contact" />; 
  }

  return (
    <div className="bg-white text-center py-16 px-4">
      <img src={Logo} alt="MOQAH.PK Logo" className="mx-auto w-24 h-24 mb-6" />
      <h2 className="text-5xl font-bold mb-6 text-[#04092C] leading-tight">
        One fixed fee a month gets you unlimited attention and <br /> credibility.
      </h2>
      <p className="text-gray-700 mb-10 max-w-2xl mx-auto text-lg font-medium">
        Feel free to ask for changes a bazillion times — we've got you covered!
      </p>
      <button
        onClick={handleButtonClick}
        className="px-8 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 text-xl font-semibold shadow-lg transform hover:scale-105 mb-16"
      >
        Send a query Now
      </button>

      {/* New Content Below */}
      <div className="mt-0">
        <h2 className="text-4xl font-bold mb-6 text-[#04092C] leading-tight">
          Your dream event <br />
          From concept to reality <br />
          in <span className="text-blue-600">2 Hours</span> or less
        </h2>
        <p className="text-gray-600 mb-12 max-w-4xl mx-auto text-lg font-light">
          Get your Customized event URL links and post on your social media
          accounts. Your private and personal Events will be personalized and
          listed with just a few key details.
        </p>

        {/* Steps Section */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-12 text-center mt-10">
          <div className="flex flex-col items-center bg-blue-50 p-8 rounded-xl shadow-lg transform hover:scale-105 transition duration-300">
            <div className="text-blue-600 text-5xl font-bold mb-4">1</div>
            <h3 className="text-2xl font-semibold text-[#04092C] mb-3">Request</h3>
            <p className="text-gray-600 text-center text-lg">
              Submit your Listing requests one at a time through our "Create your event" form.
            </p>
          </div>
          <div className="flex flex-col items-center bg-blue-50 p-8 rounded-xl shadow-lg transform hover:scale-105 transition duration-300">
            <div className="text-blue-600 text-5xl font-bold mb-4">2</div>
            <h3 className="text-2xl font-semibold text-[#04092C] mb-3">Receive</h3>
            <p className="text-gray-600 text-center text-lg">
              Get a response from our team in 1-2 hours or less. We ensure fast,
              precise delivery of your tailored event listing task.
            </p>
          </div>
          <div className="flex flex-col items-center bg-blue-50 p-8 rounded-xl shadow-lg transform hover:scale-105 transition duration-300">
            <div className="text-blue-600 text-5xl font-bold mb-4">3</div>
            <h3 className="text-2xl font-semibold text-[#04092C] mb-3">Approval</h3>
            <p className="text-gray-600 text-center text-lg">
              Enjoy your newly designed custom landing event page. Request
              unlimited revisions until it’s exactly to your liking.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
