import axios from "axios";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PdfTicket from "../components/PdfTicket";
import { pdf } from "@react-pdf/renderer";

const PurchaseConfirmationPage = () => {
  const imagePath = process.env.PUBLIC_URL + "/confirmation_illustration.svg";
  const tickIconPath = process.env.PUBLIC_URL + "/confirmation_tick.svg";
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const [userDetails, setUserDetails] = useState(null);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(`${apiUrl}/verify-token`, {
            token,
          });
          if (response.status === 200) {
            setUserDetails(response.data.userDetails);
            console.log(response.data.userDetails);
          } else {
            console.log("Failed to verify token");
            setUserDetails(undefined);
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          setUserDetails(undefined);
        }
      } else {
        setUserDetails(undefined);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    let interval;
    if (isResendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled, timer]);

  const handleOpenPdf = async () => {
    const doc = <PdfTicket invoice={'34254325536'} eventTitle={'Sample Event'} location={'Sample Location'} date={'Sample Date'} tickets={[{name: 'Sample Ticket', price: 'PKR 1000'}]} />
    const asBlob = await pdf(doc).toBlob();
    const pdfUrl = URL.createObjectURL(asBlob);
    window.open(pdfUrl, '_blank');
  }

  const handleResendTickets = () => {
    setIsResendDisabled(true);
    setTimer(60);
    // Add logic to resend tickets here
  };

  return (
    <>
      <Header />
      <div className="purchase-confimation-page-wrapper">
        <div className="purchase-confirmation-page-container">
          <img src={imagePath} className="confirmation-illustration" alt="confirmation-illustration" />
          <h6 className="heading">Tickets have been sent to</h6>
          <h6 className="email" onClick={() => navigator.clipboard.writeText(userDetails?.email)}>{userDetails?.email}</h6>

          <div className="purchase-confirmation-details">
            <img src={tickIconPath} alt="tick-illustration" />
            <h6 className="heading">Congratulations!</h6>
            <button className="download-e-ticket-button" onClick={handleOpenPdf}>Download e-Ticket</button>
            <div className="purchase-status-details">
              <div className="status-button">
                <h6>CONFIRMED</h6>
              </div>
              <div className="date-time">
                <img src={iconsPath + "calendar-black.svg"} alt="tick-icon" />
                <p>Sample Date</p>
              </div>
              <div className="date-time">
                <img
                  src={iconsPath + "calendar-black.svg"}
                  alt="calendar-icon"
                />
                <p>Sample Time</p>
              </div>
            </div>
            <h6 className="heading">Event Title</h6>
            <h6 className="amount-paid">Paid <b>PKR 1000</b></h6>
            <button className="cancel-booking-button">Cancel your booking</button>
            <h6 className="heading">No refunds/only credits</h6>
            <h6 className="heading">Haven't received your tickets yet?</h6>
            <button 
              className={isResendDisabled ? "resend-tickets-button-disabled" : "resend-tickets-button"} 
              onClick={handleResendTickets} 
              disabled={isResendDisabled}
            >
              {isResendDisabled ? `Resend in ${timer}s` : "Resend Tickets"}
            </button>
            <div className="contact-details">
              <h6 className="heading">Having trouble receiving the tickets?</h6>
              <div>
                <h6 onClick={() => navigator.clipboard.writeText('+92 321 1234 9786')}>+92 321 1234 9786</h6>
                <h6 onClick={() => navigator.clipboard.writeText('info@moqah.com')}>info@moqah.com</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PurchaseConfirmationPage;
