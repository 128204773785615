import React, { useRef, useState } from "react";
import CityCard from "./CityCard";

const TrendingCities = ({ data }) => {
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="city-list-container">
      <div className="heading-container">
        <h6 className="heading">Trending Cities</h6>
        {/* <div className="scroll-buttons">
          <button
            className="scroll-left"
            onClick={() => handleScroll(-100)}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <img src={iconsPath + "arrow-left.svg"} />
          </button>
          <button
            className="scroll-right"
            onClick={() => handleScroll(100)}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <img src={iconsPath + "arrow-right.svg"} />
          </button>
        </div> */}
      </div>
      <div className="city-scroll-container-wrapper">
        <button
          className="scroll-left"
          onClick={() => handleScroll(-100)}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <img src={iconsPath + "arrow-left.svg"} />
        </button>
        <div
          className="city-scroll-container"
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {data && data.length > 0 && (
            <div className="button-wrapper">
              <div style={{ marginRight: 1.85 + "em" }}></div>
              {data.map((item, index) => (
                <CityCard
                  heading={item.heading}
                  subheading={item.subheading}
                  imageUrl={item.imageUrl}
                  destination={item.destination}
                />
              ))}
            </div>
          )}
        </div>
        <button
          className="scroll-right"
          onClick={() => handleScroll(100)}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <img src={iconsPath + "arrow-right.svg"} />
        </button>
      </div>
    </div>
  );
};

export default TrendingCities;
